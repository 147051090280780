@import url("../App.css");

:root {
  --background-color: #222222;
  --dark-highlight: #363636;
  --panel-color: #2e2e2e;
  --almost-white: #f5f5f5;
  --dim-white: #e9e9e9;
  --dimmer-white: #d8d8d8;
  --dimmest-white: #cecece;
  --light-muted: #868686;
  --background-text: #6b6b6b;
}

.appMount {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
}

.unselectable {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.formContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  min-width: 360px;
  max-width: 460px;
  height: 390px;
  border-radius: 10px;
  background-color: var(--panel-color);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 5px rgb(0, 0, 0, 10%);
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.directLoginContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  min-width: 360px;
  max-width: 460px;
  height: 260px;
  border-radius: 10px;
  background-color: var(--panel-color);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 5px rgb(0, 0, 0, 10%);
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  pointer-events: all;
}

.title {
  color: var(--almost-white);
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}

.directLoginText {
  text-align: center;
  font-size: 20px;
  color: var(--light-muted);
  margin-bottom: 15px;
}

.entryError {
  margin-top: 50px;
  color: var(--light-muted);
  font-size: 17px;
  text-align: center;
}

.nfr h1 {
  position: absolute;
  font-weight: bold;
  font-size: 100px;
  letter-spacing: 20px;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--background-text);
}

.nfr div {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 25px;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  color: var(--background-text);
  margin-top: 100px;
}

.playerArea {
  position: relative;
  box-sizing: border-box;
  left: 0;
  top: 50px;
  height: fit-content;
  max-height: 800px;
  width: 300px;
  padding: 13px;
  z-index: 2;
  overflow-y: auto;
  scrollbar-width: 0;
  -ms-overflow-style: none;
}

.playerArea::-webkit-scrollbar {
  width: 0;
}

.playerArea h2 {
  color: var(--almost-white);
  font-size: 30px;
  margin-bottom: 5px;
}

.changeNicknameButton {
  width: 100%;
  top: 10px;
}

.gameTimer {
  text-align: center;
  font-size: 25px;
}

.mainArea {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: calc(100vw - 700px);
  height: 100%;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.topSector {
  display: flex;
  height: 100px;
  width: 100%;
  flex: none;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.statusHeader {
  color: var(--dim-white);
  font-weight: normal;
  font-size: 25px;
}

.statusSubHeader {
  font-weight: normal;
  margin-top: 10px;
  margin-right: 40px !important;
  margin-left: 40px !important;
  color: var(--dimmest-white);
}

@media screen and (max-width: 1600px) {
  .playerArea {
    transform-origin: top left;
    transform: scale(0.8);
  }

  .mainArea {
    width: calc(100vw - 500px);
  }
}

@media screen and (max-width: 1200px) {
  .appMount {
    display: flex;
    flex-flow: column;
  }
  .mainArea {
    top: 0;
    display: flexbox;
    position: relative;
    width: 100vw;
    min-height: 45vh;
    flex: 1 1 auto;
  }

  .topSector {
    height: 70px;
  }

  .playerArea {
    transform: scale(1);
    display: flex;
    flex-flow: column;
    flex: 1 0 auto;
    top: unset;
    bottom: 0px;
    width: 100vw;
    height: fit-content;
    max-height: 35vh;
  }

  .changeNicknameButton {
    max-width: 200px;
    display: flex;
    align-self: center;
  }

  .gameTimer {
    font-size: 15px;
  }
}
